import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import useDeviceDetect from '../../js/hooks/useDeviceDetect'

import './styles.scss'

function TextRotator(props) {
	const { isMobile } = useDeviceDetect()
	const tl = useRef(gsap.timeline())

	useEffect(() => {
		const dur = 0.5
		const pause = 0.5
		const dist = 20
		const ease = 'expo.out'
		tl.current.pause()
		tl.current.from('#line1', {
			y: `-=${dist}`,
			duration: dur,
			opacity: 0,
			ease: ease,
		})
		tl.current.to(
			'#line1',
			{
				y: `+=${dist}`,
				duration: dur,
				opacity: 0,
				ease: ease,
			},
			`>${pause}`
		)

		tl.current.from('#line2', {
			y: `-=${dist}`,
			duration: dur,
			opacity: 0,
			ease: ease,
		})
		tl.current.to(
			'#line2',
			{
				y: `+=${dist}`,
				duration: dur,
				opacity: 0,
				ease: ease,
			},
			`>${pause}`
		)

		tl.current.from('#line3', {
			y: `-=${dist}`,
			duration: dur,
			opacity: 0,
			ease: ease,
		})
		tl.current.to(
			'#line3',
			{
				y: `+=${dist}`,
				duration: dur,
				opacity: 0,
				ease: ease,
			},
			`>${pause}`
		)
		tl.current.from('#line4', {
			y: `-=${dist}`,
			duration: dur,
			opacity: 0,
			ease: ease,
		})
		gsap.delayedCall(0.5, () => {
			tl.current.resume()
		})
	}, [tl])

	return (
		<span className="textrotator">
			<span id="line1" className="textrotator__text">
				display ads,
			</span>
			<span id="line2" className="textrotator__text">
				motion design,
			</span>
			<span id="line3" className="textrotator__text">
				emails,
			</span>
			<span id="line4" className="textrotator__text">
				cool stuff,
			</span>
			<span className="textrotator__sizer">cool stuff,</span>
		</span>
	)
}

export default TextRotator
