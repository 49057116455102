import React from 'react'
import './styles.scss'

function AdVideo(props) {
	const { src, w, h } = props

	const styles = {
		width: `${w}px`,
		height: `${h}px`,
	}

	return (
		<div className="advideo" style={styles}>
			<video controls className="advideo__videoplayer" playsInline autoPlay preload="metadata" width={w} height={h} key={src}>
				<source src={src} type="video/mp4" />
			</video>
		</div>
	)
}

export default AdVideo
