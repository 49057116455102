import React, { useState, useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { Link } from 'react-router-dom'
import './styles.scss'

import Preloader from '../Preloader'

function GridItem(props) {
	const { name, route, clientfolder, projectfolder, typeOfCreative } = props.itemData

	const [isLoaded, setIsLoaded] = useState(false)
	const placeholderRef = useRef()

	const logoStyles = {
		backgroundImage: `url("${route}/${clientfolder}/${projectfolder}/logo.png")`,
	}

	useEffect(() => {
		if (isLoaded) {
			gsap.to(placeholderRef.current, {
				opacity: 0,
				duration: 0.5,
				onComplete: () => {
					gsap.set(placeholderRef.current, { delay: 0.5, display: 'none' })
				},
			})
		}
	}, [isLoaded])

	return (
		<div className="griditem__item">
			<Link to={`${route}/${name}`}>
				<img
					src={`${route}/${clientfolder}/${projectfolder}/thumb.jpg`}
					alt=""
					style={{
						width: '100%',
						height: '100%',
						position: 'absolute',
						objectFit: 'cover',
					}}
					loading="lazy"
					onLoad={() => setIsLoaded(true)}
				/>

				<div className="griditem__item-highlight" />
				<div className="griditem__item-logo" style={logoStyles} />
				<div className="griditem__item-typeOfCreative">{typeOfCreative}</div>
				<div className="griditem__item-overlay">yap</div>

				<div className="griditem__placeholder" ref={placeholderRef}>
					<Preloader className="griditem__placeholder-preloader" />
				</div>
			</Link>
		</div>
	)
}
export default GridItem
