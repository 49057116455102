import { useEffect, useState, useMemo } from 'react'
import './styles.scss'

import GridItem from '../GridItem/'

function Grid(props) {
	const { data } = props

	const numItems = 8
	const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('currentPage') || 1)
	const [currentData, setCurrentData] = useState([])
	const [totalPages, setTotalPages] = useState(0)

	const shuffleArray = (array) => {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1))
			const temp = array[i]
			array[i] = array[j]
			array[j] = temp
		}
		return array
	}

	const gridData = useMemo(() => {
		const stickyData = data.data.slice(0, numItems)
		const shuffledData = shuffleArray(data.data.slice(numItems, data.data.length))
		return [...stickyData, ...shuffledData]
	}, [data])

	useEffect(() => {
		sessionStorage.setItem('currentPage', currentPage)
		setTotalPages(gridData.length / numItems)
		setCurrentData(gridData.slice(0, currentPage * numItems))
	}, [gridData, currentPage])

	const loadNextPage = () => {
		const newPage = currentPage + 1
		sessionStorage.setItem('currentPage', newPage)
		const newData = gridData.slice(currentPage * numItems, newPage * numItems)
		setCurrentData([...currentData, ...newData])
		setCurrentPage((prevPage) => prevPage + 1)
	}

	const loadFirstPage = () => {
		const newPage = 1
		sessionStorage.setItem('currentPage', newPage)
		setCurrentData(gridData.slice(0, currentPage * numItems))
		setCurrentPage(1)
		const htmlads = document.getElementById('htmlads')
		htmlads.scrollIntoView()
	}

	return (
		<div id="htmlads" className="grid">
			<div className="grid__header">Client Projects - Motion Design, HTML Ads and HTML Emails</div>
			<div className="grid__container">
				{currentData.map((item) => {
					item.route = data.path
					return <GridItem key={item.name} itemData={item} onGridItemClick={props.onGridItemClick} />
				})}
			</div>
			{currentPage < totalPages ? (
				<div className="grid__showmore" onClick={loadNextPage}>
					<svg className="grid__showmore-icon" x="0px" y="0px" viewBox="0 0 512 512">
						<path d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M127,297c-9.4-9.4-9.4-24.6,0-33.9s24.6-9.4,33.9,0l71,71L232,120c0-13.3,10.7-24,24-24c13.3,0,24,10.7,24,24v214.1l71-71c9.4-9.4,24.6-9.4,33.9,0s9.4,24.6,0,33.9L273,409c-9.4,9.4-24.6,9.4-33.9,0L127,297z" />
					</svg>
					Show More Projects
				</div>
			) : (
				<div className="grid__showless" onClick={loadFirstPage}>
					<svg className="grid__showless-icon" x="0px" y="0px" viewBox="0 0 512 512">
						<path d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M127,297c-9.4-9.4-9.4-24.6,0-33.9s24.6-9.4,33.9,0l71,71L232,120c0-13.3,10.7-24,24-24c13.3,0,24,10.7,24,24v214.1l71-71c9.4-9.4,24.6-9.4,33.9,0s9.4,24.6,0,33.9L273,409c-9.4,9.4-24.6,9.4-33.9,0L127,297z" />
					</svg>
					Close Projects
				</div>
			)}
		</div>
	)
}

export default Grid
