import './App.scss'
import Home from './components/Home/'
import { useState, useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import AdDetail from './components/AdDetail/'
import Preloader from './components/Preloader/'
import { gsap } from 'gsap'
import ReactGA from 'react-ga4'

function App() {
	let [siteData, setSiteData] = useState({})
	const [dataIsLoaded, setDataIsLoaded] = useState(false)

	let location = useLocation()

	useEffect(() => {
		ReactGA.initialize('G-7S6SDLS873')
		ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
	}, [location])

	useEffect(() => {
		fetch('/data/sitedata.json')
			.then((res) => res.json())
			.then((data) => {
				setSiteData(data)
				setDataIsLoaded(true)
				gsap.to('.app__preloader-container', {
					duration: 0.3,
					opacity: 0,
					onComplete: () => {
						gsap.set('.app__preloader-container', { delay: 0.5, display: 'none' })
					},
				})
			})
	}, [])

	return (
		<div className="app">
			<Routes>
				<Route exact path="/" element={<Home dataIsLoaded={dataIsLoaded} siteData={siteData} />} />
				<Route path="/displayads/:adId" element={<AdDetail />} />
			</Routes>
			<div className="app__preloader-container">
				<Preloader className="app__preloader-container-preloader" />
			</div>
		</div>
	)
}

export default App
