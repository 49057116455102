import React from "react";
import "./styles.scss";

function Preloader({ className }) {
  return (
    <div className={`preloader-ellipsis ${className ? className : ""}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default Preloader;
