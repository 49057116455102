import { useState, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import './styles.scss'

import Preloader from '../Preloader'
import Grid from '../Grid/'
import Header from '../Header/'
import Clients from '../Clients/'
import Sites from '../Sites/'
import Tools from '../Tools'
import Social from '../Social'
import AdDetail from '../AdDetail'
import Dooh from '../Dooh'

function Home({ dataIsLoaded, siteData }) {
	const [showAd, setShowAd] = useState()

	let location = useLocation()
	useEffect(() => {
		ReactGA.initialize('G-7S6SDLS873')
		ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
	}, [location])

	const onGridItemClick = (name) => {
		setShowAd(name)
	}

	const onBackBtnClick = () => {
		setShowAd(null)
	}

	return (
		<>
			<div className="home" style={{ display: showAd ? 'none' : 'block' }}>
				{dataIsLoaded ? (
					<>
						<Header />
						<Grid data={siteData.ads} onGridItemClick={onGridItemClick} />
						{/* <Sites data={siteData.sites} /> */}
						{/* <Tools /> */}
						{/* <Social data={siteData.social} /> */}
						{/* <Dooh /> */}
						{/* <div className="home__backtotop">
							<img
								className="home__backtotop-icon"
								src="images/uparrow.svg"
								alt=""
								onClick={() => {
									document.body.scrollTop = 0 // For Safari
									document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
								}}
							/>
							Back To Top
						</div> */}
						<Clients />
					</>
				) : (
					<div className="app__preloader-container">
						<Preloader className="app__preloader-container-preloader" />
					</div>
				)}
			</div>
			{showAd && <AdDetail adName={showAd} onBackBtnClick={onBackBtnClick} />}
		</>
	)
}

export default Home
