import React from 'react'
import './styles.scss'

function AdFrame(props) {
	const { src, w, h } = props

	const styles = {
		width: `${w}px`,
		height: `${h}px`,
	}

	return (
		<div className="adframe" style={styles}>
			<iframe key={src} title="ad" frameBorder="0" scrolling="no" src={src} width={w} height={h}></iframe>
		</div>
	)
}

export default AdFrame
