import React from 'react'
import './styles.scss'

import TextRotator from '../TextRotator'

function Header(props) {
	return (
		<div className="header">
			<div className="header__container">
				Hi, I'm Steve. <br />I make <TextRotator />
				<div className="header__subhead">and much much more.</div>
				<div className="header__icons">
					<a href="SteveArbizoResume.pdf" target="_blank" rel="noreferrer">
						<img src="images/resume.svg" className="header__resume" alt="Resume" />
					</a>
					<a href="https://www.linkedin.com/in/steve-arbizo-b777186/" target="_blank" rel="noreferrer">
						<img src="images/linkedin.svg" className="header__linkedin" alt="Linked in" />
					</a>
					<a href="mailto:stevearbizo@gmail.com">
						<img src="images/email.svg" className="header__email" alt="Email Me" />
					</a>
				</div>
			</div>
		</div>
	)
}

export default Header
