import React, { useState } from 'react'
import './styles.scss'

function VolumeButton({ clickHandler }) {
	const [muted, setMuted] = useState(true)

	return (
		<div
			className="volumebutton"
			onClick={() => {
				const current = muted
				setMuted(!current)
				clickHandler(!current)
			}}
		>
			{muted ? (
				<img className="volumebutton__icon" src="images/volume-x.svg" alt="volume off" />
			) : (
				<img className="volumebutton__icon" src="images/volume.svg" alt="volume on" />
			)}
		</div>
	)
}

export default VolumeButton

VolumeButton.defaultProps = {
	playerId: 0,
	clickHandler: () => {},
}
