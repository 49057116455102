import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'
import './styles.scss'

import Preloader from '../Preloader'
import AdFrame from '../AdFrame'

import AdVideo from '../AdVideo'

function AdDetail(props) {
	const navigate = useNavigate()
	let { adId } = useParams() // Unpacking and retrieve id
	const [adData, setAdData] = useState({})
	const [dataIsLoaded, setDataIsLoaded] = useState(false)
	const [selectedSize, setSelectedSize] = useState('600x500')
	const [selectedDescriptive, setSelectedDescriptive] = useState('')
	const [bgColors, setBgColors] = useState(['transparent', 'transparent'])
	const [mode, setMode] = useState('light')

	useEffect(() => {
		document.title = `SA - ${adId}`
	}, [adId])

	let location = useLocation()
	useEffect(() => {
		ReactGA.initialize('G-7S6SDLS873')
		ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
	}, [location])

	useEffect(() => {
		const url = `/data/sitedata.json`
		fetch(url)
			.then((res) => res.json())
			.then((res) => {
				setDataIsLoaded(true)
				const found = res.ads.data.find((ad) => ad.name === adId)
				setAdData(found)
				setSelectedSize(found.sizes[0])
				setSelectedDescriptive(found.descriptive[0])
				setBgColors([found.colors[0], found.colors[1]])
				setMode(found.mode)
			})
	}, [adId])

	const getAdDimensions = (dim) => {
		const size = selectedSize.split('x')
		return dim === 'width' ? size[0] : size[1]
	}

	const handleSizeClick = (size) => {
		setSelectedSize(size)
	}

	const [replayKey, setReplayKey] = useState(1)

	const replayAd = () => {
		setReplayKey(Math.random())
	}

	const renderSizes = () => {
		if (!adData.sizes) return

		return adData.sizes.map((size) => {
			return (
				<div className="addetail__sizechip" key={uuidv4()} onClick={() => handleSizeClick(size)}>
					{size} {selectedDescriptive}
				</div>
			)
		})
	}

	return (
		<div
			className="addetail"
			style={{
				background: `radial-gradient(${bgColors[0]}, ${bgColors[1]})`,
			}}
		>
			{dataIsLoaded ? (
				<>
					<div className={`addetail__header addetail__header--${mode}`}>
						<div className="addetail__backbtn" onClick={() => navigate('/')}>
							<img src={`${process.env.PUBLIC_URL}/images/gridbtn.svg`} alt="Back to Grid" />
						</div>
						<div className="addetail__header-client">{adData.client}</div>
						<div className="addetail__header-divider">/</div>
						<div className="addetail__header-project">{adData.project}</div>
					</div>
					{adData.type === 'video' ? (
						<AdVideo
							src={`${process.env.PUBLIC_URL}/displayads/${adData.clientfolder}/${adData.projectfolder}/${selectedSize}/${adData.videofileprefix}_${selectedSize}.mp4?replay=${replayKey}`}
							w={getAdDimensions('width')}
							h={getAdDimensions('height')}
							replayKey={replayKey}
						/>
					) : adData.type === 'html' ? (
						<AdFrame
							src={`/displayads/${adData.clientfolder}/${adData.projectfolder}/${selectedSize}?replay=${replayKey}`}
							w={getAdDimensions('width')}
							h={getAdDimensions('height')}
							replayKey={replayKey}
						/>
						) : adData.type === 'singleVideo' ? (
							<AdVideo
							src={`${process.env.PUBLIC_URL}/displayads/${adData.clientfolder}/${adData.projectfolder}/${selectedSize}/${adData.videofileprefix}_${selectedSize}.mp4?replay=${replayKey}`}
							w={getAdDimensions('width')}
							h={getAdDimensions('height')}
							replayKey={replayKey}
						/>	
							) : ("")}

					<div className={`addetail__footer addetail__footer--${mode}`}>
						<div className="addetail__sizescontainer">
						
							{adData.type === 'html' ? (
								<div className="addetail__sizescontainer-replay" onClick={replayAd}>
									REPLAY AD
									<svg viewBox="0 0 512 512" className="addetail__sizescontainer-replaybtn">
										<path d="M463.5 224H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5z" />
									</svg>
								</div>
								
							) : adData.type === 'video' ? (
								<div className="addetail__sizescontainer-replay" onClick={replayAd}>
									REPLAY VIDEO
									<svg viewBox="0 0 512 512" className="addetail__sizescontainer-replaybtn">
										<path d="M463.5 224H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5z" />
									</svg>
								</div>
								) : adData.type === 'singleVideo' ? (
									<div className="addetail__sizescontainer-replay" onClick={replayAd}>
									REPLAY VIDEO
									<svg viewBox="0 0 512 512" className="addetail__sizescontainer-replaybtn">
										<path d="M463.5 224H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5z" />
									</svg>
								</div>
							) : ("")}

							{adData.type === 'html' ? (
								<div className="addetail__sizescontainer-sizes">{renderSizes()}</div>
							) : adData.type === 'video' ? (
								<div className="addetail__sizescontainer-sizes">{renderSizes()}</div>
							) : adData.type === 'singleVideo' ? ("") : ("")}
							
						</div>
					</div>
				</>
			) : (
				<div className="addetail__preloader-container">
					<Preloader className="addetail__preloader-container-preloader" />
				</div>
			)}
		</div>
	)
}

export default AdDetail
