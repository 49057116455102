import React from "react";
import "./styles.scss";

function Clients(props) {
  const clientList = [
    {
      icon: "nbc",
      scalefactor: "1.1",
    },
    {
      icon: "gme",
      scalefactor: "1",
    },
    // {
    //   icon: "apple",
    //   scalefactor: "1",
    // },
    {
      icon: "budlight",
      scalefactor: ".85",
    },
    {
      icon: "disney",
      scalefactor: "1",
    },
    {
      icon: "espn",
      scalefactor: "1",
    },
    {
      icon: "hulu",
      scalefactor: ".8",
    },
    // {
    //   icon: "marvel",
    //   scalefactor: ".95",
    // },
    // {
    //   icon: "msnbc",
    //   scalefactor: ".7",
    // },
    // {
    //   icon: "natgeo",
    //   scalefactor: "1",
    // },
    // {
    //   icon: "netflix",
    //   scalefactor: ".9",
    // },
    // {
    //   icon: "nike",
    //   scalefactor: "1",
    // },
    // {
    //   icon: "paramount",
    //   scalefactor: "1",
    // },
    // {
    //   icon: "sony",
    //   scalefactor: ".7",
    // },
    // {
    //   icon: "twitch",
    //   scalefactor: "1",
    // },
    // {
    //   icon: "underarmour",
    //   scalefactor: "1",
    // },
  ];

  // const element = document.querySelector(".clients__icon");
  // const style = getComputedStyle(element);
  // console.error(style.height);

  return (
    <div className="clients">
      <div className="clients__header">
        Clients I have had the pleasure of working with:
      </div>
      <div className="clients__container">
        {clientList.map((client) => {
          const iconHeight = 30 * client.scalefactor;
          return (
            <img
              className="clients__icon"
              key={client.icon}
              src={`images/clients/${client.icon}.svg`}
              alt=""
              style={{ height: iconHeight }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Clients;
